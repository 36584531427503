(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AdmissionMedicationDialogController', AdmissionMedicationDialogController);

    AdmissionMedicationDialogController.$inject = ['$uibModalInstance', 'chart', 'PatientMedication', 'CoreService', 'Route',
        'DoseForm', 'Relational', 'MedicationSearch', 'admissionMedication',
        'GUIUtils', 'Employee', 'AdmissionMedication', '$scope', 'RATIONALE_TYPE'];

    function AdmissionMedicationDialogController($uibModalInstance, chart, PatientMedication, CoreService, Route,
                                                 DoseForm, Relational, MedicationSearch, admissionMedication,
                                                 GUIUtils, Employee, AdmissionMedication, $scope, RATIONALE_TYPE) {
        var vm = this;

        vm.admissionMedication = admissionMedication;
        vm.chart = chart;
        vm.matches = {};
        vm.routes = Route.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.doseForms = DoseForm.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.relationals = Relational.findAllByFilter(getFilterByCorporationAndType());
        vm.admissionMedicationActions = GUIUtils.getAdmissionMedicationAction();
        vm.frequencies = PatientMedication.getAllMedicationFrequency();
        vm.facility = CoreService.getCurrentFacility();
        vm.matches = {};
        vm.scheduleStrength = null;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.expirationDate = false;

        vm.openCalendar = openCalendar;
        vm.getMedications = getMedications;
        vm.changeFrequency = changeFrequency;
        vm.clear = clear;
        vm.save = save;
        vm.getEmployees = getEmployees;

        init();

        function init() {
            vm.scheduleStrength = vm.admissionMedication.id ? vm.admissionMedication.admissionFrequencies[0].schedules[0].strength : null;
            vm.getEmployees();

            PatientMedication.match({id: vm.chart.id}, function (medications) {
                _.forEach(medications, function (medication) {
                    vm.matches[medication.id] = true;
                })
            });
        }

        vm.currentDate = {
            minDate: new Date()
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getMedications(query) {
            if (query && query.length > 3) {
                query = _.lowerCase(query);
                vm.medications = MedicationSearch.query({
                    query: query
                });
            }
        }

        function changeFrequency(frequency) {
            frequency.schedules = [];
            frequency.hideDay = false;

            switch (frequency.frequency.id) {
                case 'EVERY_H':
                case 'EVERY_2H':
                case 'EVERY_3H':
                case 'EVERY_4H':
                case 'EVERY_6H':
                case 'EVERY_8H':
                case 'EVERY_12H':
                case 'EVERY_24H':
                case 'EVERY_48H':
                case 'EVERY_WEEK':
                case 'EVERY_MONTH': {
                    frequency.hideDay = true;
                    scheduleTakesIntervals(frequency, 1, 0, 1);

                    break;
                }
                case 'ONE_AM': {
                    scheduleTakesIntervals(frequency, 1, 0, 9);

                    break;
                }
                case 'ONE_HS': {
                    scheduleTakesIntervals(frequency, 1, 0, 21);

                    break;
                }
                case 'BID': {
                    scheduleTakesIntervals(frequency, 2, 12, 9);

                    break;
                }
                case 'TID': {
                    scheduleTakesIntervals(frequency, 3, 6, 9);

                    break;
                }
                case 'QID': {
                    scheduleTakesIntervals(frequency, 4, 3, 9);

                    break;
                }
                case 'SID': {
                    scheduleTakesIntervals(frequency, 6, 4, 2);

                    break;
                }
                case 'ONCE': {
                    scheduleTakesIntervals(frequency, 1, 0, 15);

                    break;
                }
                case 'STAT': {
                    frequency.schedules.push({
                        xorder: 0,
                        strength: null,
                        date: moment().startOf('minute').toDate()
                    });

                    break;
                }
            }
        }

        function scheduleTakesIntervals(frequency, count, hours, firstHour) {
            var first = moment().startOf('hour').toDate();
            first.setHours(firstHour);

            for (var i = 0; i < count; i++) {
                frequency.schedules.push({
                    xorder: i,
                    date: angular.copy(first),
                    strength: null,
                    quantity: 0
                });

                first.setHours(first.getHours() + hours);
            }
        }

        function save() {
            vm.isSaving = true;

            var data = angular.copy(vm.admissionMedication);
            data.lastDate = moment.tz(moment(data.lastDate).format('YYYY-MM-DDTHH:mm:00'), vm.facility.timeZone).format();
            data.expirationDate = moment.tz(moment(data.expirationDate).format('YYYY-MM-DD'), vm.facility.timeZone).format();

            _.forEach(data.admissionFrequencies, function (frequency) {
                _.forEach(frequency.schedules, function (schedule) {
                    schedule.date = moment(schedule.date).format('YYYY-MM-DDTHH:mm:00');
                    schedule.strength = vm.scheduleStrength;
                });
            });

            if (vm.admissionMedication.id !== null) {
                AdmissionMedication.update(data, onSaveSuccess, onSaveError);
            } else {
                AdmissionMedication.save(data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bluebookApp:admissionMedicationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getFilterByCorporationAndType() {
            var filter = {};

            filter.corporationId = CoreService.getCorporation().id;
            filter.relationalType = RATIONALE_TYPE.MEDICATION;

            return filter
        }

        function getEmployees(query) {
            var filter = {page: 0, size: 20, query: query, facilityId: CoreService.getCurrentFacility().id};

            Employee.findAllByFilter(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();
