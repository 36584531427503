(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('PatientMedicationChart2DialogController', PatientMedicationChart2DialogController);

    PatientMedicationChart2DialogController.$inject = ['$uibModalInstance', 'chart', 'CoreService',
        'Protocol', 'DataTablesService', 'MedicationSearch', 'Relational',
        'Route', 'DoseForm', 'Via', 'Employee',
        'BusinessCredential', 'BUSINESS_ROLES', 'PatientMedication',
        'admissionMedications', 'AdmissionMedication', 'patientMedication',
        'RATIONALE_TYPE', 'evaluation', 'itHasCredentials'];

    function PatientMedicationChart2DialogController($uibModalInstance, chart, CoreService,
                                                     Protocol, DataTablesService, MedicationSearch, Relational,
                                                     Route, DoseForm, Via, Employee,
                                                     BusinessCredential, BUSINESS_ROLES, PatientMedication,
                                                     admissionMedications, AdmissionMedication, patientMedication,
                                                     RATIONALE_TYPE, evaluation, itHasCredentials) {
        var vm = this;

        vm.itHasCredentials = itHasCredentials.value;
        vm.chart = chart;
        vm.admissionMedications = admissionMedications;
        vm.page = 1;
        vm.itemsPerPage = 25;
        vm.protocolType = 'ALL';
        vm.selected = {};
        vm.selectAll = false;
        vm.pmedications = [];
        vm.relationals = Relational.findAllByFilter(getFilterByCorporationAndType());
        vm.doseForms = DoseForm.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.routes = Route.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.frequencies = PatientMedication.getAllMedicationFrequency();
        vm.vias = Via.findAllByCorporation(CoreService.getFilterByCurrentCorporation());
        vm.via = null;
        vm.physician = null;
        vm.facility = CoreService.getCurrentFacility();
        vm.isEnabledToSign = false;
        vm.employee = CoreService.getCurrentEmployee();
        vm.matches = {};
        vm.employeeSignature = '';
        vm.employeeSignaturePin = null;
        vm.signatureOpts = getSignatureOptions();
        vm.odsSignatureNameId = Math.floor((Math.random() * 100) + 1);
        vm.patientMedication = patientMedication;

        vm.clear = clear;
        vm.getProtocols = getProtocols;
        vm.changeProtocolType = changeProtocolType;
        vm.deployProtocols = deployProtocols;
        vm.getMedications = getMedications;
        vm.deleteMedication = deleteMedication;
        vm.deleteDay = deleteDay;
        vm.addDay = addDay;
        vm.changeFrequency = changeFrequency;
        vm.add = add;
        vm.changeCategory = changeCategory;
        vm.save = save;
        vm.checkAll = checkAll;
        vm.unCheckAll = unCheckAll;
        vm.getEmployees = getEmployees;

        vm.toggleOne = DataTablesService.toggleOne;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.getSelectedIds = DataTablesService.getSelectedIds;

        init();

        function init() {
            if (vm.itHasCredentials) {
                // vm.isEnabledToSig = vm.itHasCredentials;
                vm.physician = vm.employee;
                vm.employees = [vm.employee];
            } else {
                vm.getEmployees();
            }

            PatientMedication.match({id: vm.chart.id}, function (medications) {
                _.forEach(medications, function (medication) {
                    vm.matches[medication.id] = true;
                })
            });

            if (admissionMedications && admissionMedications.ids && admissionMedications.ids.length > 0) {
                getAdmissionMedications();
            } else {
                getProtocols();
            }

            if (vm.patientMedication) {
                var pM = angular.copy(vm.patientMedication);
                var auxFrequencies = [];

                if (pM.frequencies) {
                    _.forEach(pM.frequencies, function (frequency) {
                        var auxFrequency = angular.copy(frequency);

                        auxFrequency.id = null;
                        auxFrequency.hideDay = false;

                        switch (auxFrequency.frequency.id) {
                            case 'EVERY_H':
                            case 'EVERY_2H':
                            case 'EVERY_3H':
                            case 'EVERY_4H':
                            case 'EVERY_6H':
                            case 'EVERY_8H':
                            case 'EVERY_12H':
                            case 'EVERY_24H':
                            case 'EVERY_48H':
                            case 'EVERY_WEEK':
                            case 'EVERY_MONTH': {
                                auxFrequency.hideDay = true;

                                break;
                            }
                        }

                        if (auxFrequency.schedules) {
                            _.forEach(auxFrequency.schedules, function (schedule) {
                                schedule.id = null;
                            });
                        }

                        auxFrequencies.push(auxFrequency);
                    });
                }

                var pMedication = {
                    medication: pM.medication,
                    route: pM.route,
                    doseForm: pM.doseForm,
                    refills: pM.refills,
                    dispense: pM.dispense,
                    relational: pM.relational,
                    type: pM.type,
                    durationDays: pM.durationDays,
                    instruction: pM.instruction,
                    collapsed: false,
                    startDate: new Date(),
                    frequencies: auxFrequencies,
                    onlyInChart: pM.onlyInChart,
                    fromPatientMedicationId: vm.patientMedication.medicationStatus == 'SCHEDULED' ? vm.patientMedication.id : null
                }

                vm.pmedications.push(pMedication);

                vm.physician = vm.patientMedication.signedBy;
                vm.via = vm.patientMedication.via;
            }
        }

        function save() {
            vm.isSaving = true;

            var patientMedicationProj = {
                patientMedications: [],
                chartId: vm.chart.id,
                chart: chart,
                signature: vm.employeeSignature,
                pin: vm.employeeSignaturePin,
                physician: vm.physician,
                evaluation: evaluation,
                via: vm.via
            };

            _.forEach(vm.pmedications, function (pMedication) {
                var patientMedication = angular.copy(pMedication);

                _.forEach(patientMedication.frequencies, function (frequency) {
                    _.forEach(frequency.schedules, function (schedule) {
                        schedule.date = moment(schedule.date).format('YYYY-MM-DDTHH:mm:00');
                    });
                });

                var pm = {
                    id: null,
                    chart: null,
                    via: vm.via,
                    startDate: moment.tz(moment(patientMedication.startDate).format('YYYY-MM-DDTHH:mm:00'), vm.facility.timeZone).format(),
                    endDate: patientMedication.endDate,
                    route: patientMedication.route,
                    instruction: patientMedication.instruction,
                    durationDays: patientMedication.durationDays,
                    doseForm: patientMedication.doseForm,
                    medication: patientMedication.medication,
                    relational: patientMedication.relational,
                    noted: false,
                    refills: patientMedication.refills,
                    dispense: patientMedication.dispense,
                    type: patientMedication.type,
                    protocolId: patientMedication.protocolId,
                    frequencies: patientMedication.frequencies,
                    admissionMedication: patientMedication.admissionMedication,
                    onlyInChart: patientMedication.onlyInChart,
                    fromPatientMedicationId: patientMedication.fromPatientMedicationId
                };

                patientMedicationProj.patientMedications.push(pm);
            });

            PatientMedication.create(patientMedicationProj, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.finishedWizard = finishedWizard;
        vm.cancelledWizard = cancelledWizard;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function finishedWizard() {

        }

        function cancelledWizard() {

        }

        function getFilter() {
            var filter = {};

            filter.facilityId = CoreService.getCurrentFacility().id;
            filter.page = vm.page - 1;
            filter.size = vm.itemsPerPage;
            filter.searchQuery = vm.searchQuery;
            filter.active = true;
            filter.type = vm.protocolType === 'ALL' ? null : vm.protocolType;

            return filter
        }

        function getProtocols() {
            Protocol.findAllByFilter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.protocols = result;

                _.forEach(vm.protocols, function (protocol) {
                    if (vm.selected[protocol.id] === undefined) {
                        vm.selected[protocol.id] = false;
                    }
                })
            });
        }

        function changeProtocolType() {
            vm.page = 1;
            getProtocols();
        }

        function deployProtocols() {
            vm.getSelectedIds = DataTablesService.getSelectedIds(vm.selected);

            if (vm.getSelectedIds.length > 0) {
                Protocol.findAll({
                    ids: vm.getSelectedIds,
                    facilityId: CoreService.getCurrentFacility().id
                }, function (protocols) {
                    vm.pmedications = _.map(protocols, function (protocol) {

                        return medicationFromProtocol(protocol);
                    });
                });
            }
        }

        function medicationFromProtocol(protocol) {
            var medication = angular.copy(protocol);
            medication.refills = protocol.refills;
            medication.dispense = protocol.dispense;
            medication.collapsed = true;
            medication.startDate = new Date();
            medication.protocolId = protocol.id;
            medication.id = null;
            medication.relational = protocol.relationals;
            medication.instruction = protocol.instructions;
            medication.onlyInChart = false;
            medication.frequencies = _.map(protocol.protocolFrequencies, function (frequency) {

                return {
                    id: null,
                    xorder: frequency.xorder,
                    frequency: frequency.frequency,
                    hideDay: frequency.hideDay,
                    schedules: _.map(frequency.schedules, function (schedule) {

                        return {
                            id: null,
                            strength: schedule.strength,
                            quantity: schedule.quantity,
                            xorder: schedule.xorder,
                            date: schedule.date
                        };
                    })
                }
            });

            return medication;
        }

        function medicationFromAdmissionMedication(admissionMedication) {
            var medication = {};
            medication.onlyInChart = false;
            medication.admissionMedication = admissionMedication;
            medication.collapsed = true;
            medication.medication = admissionMedication.medication;
            medication.route = admissionMedication.route;
            medication.doseForm = admissionMedication.doseForm;
            medication.durationDays = admissionMedication.durationDays;
            medication.startDate = new Date();
            medication.protocolId = null;
            medication.id = null;
            medication.relational = admissionMedication.relationals;
            medication.instruction = admissionMedication.instructions;
            medication.type = admissionMedication.asNeeded ? 'AS_NEEDED' : 'CONSISTENT_DOSE';
            medication.frequencies = _.map(admissionMedication.admissionFrequencies, function (frequency) {

                return {
                    id: null,
                    xorder: frequency.xorder,
                    frequency: frequency.frequency,
                    hideDay: frequency.hideDay,
                    schedules: _.map(frequency.schedules, function (schedule) {

                        return {
                            id: null,
                            strength: schedule.strength,
                            quantity: schedule.quantity,
                            xorder: schedule.xorder,
                            date: schedule.date
                        };
                    })
                }
            });

            return medication;
        }

        function getMedications(query) {
            if (query && query.length > 3) {
                query = _.lowerCase(query);
                vm.medications = MedicationSearch.query({
                    query: query
                });
            }
        }

        function deleteMedication(index) {
            vm.pmedications.splice(index, 1);
        }

        function deleteDay(pmedication, index) {
            pmedication.frequencies.splice(index, 1);

            _.forEach(pmedication.frequencies, function (frequency, index) {
                frequency.xorder = index;
            })
        }

        function addDay(pmedication, index) {
            var frequency = {
                frequency: null,
                xorder: null
            }

            pmedication.frequencies.splice(index == null ? pmedication.frequencies.length : index, 0, frequency);
            pmedication.frequencies.forEach(function (freq, i) {
                freq.xorder = i;
            });
        }

        function changeFrequency(frequency) {
            frequency.schedules = [];
            frequency.hideDay = false;

            switch (frequency.frequency.id) {
                case 'EVERY_H':
                case 'EVERY_2H':
                case 'EVERY_3H':
                case 'EVERY_4H':
                case 'EVERY_6H':
                case 'EVERY_8H':
                case 'EVERY_12H':
                case 'EVERY_24H':
                case 'EVERY_48H':
                case 'EVERY_WEEK':
                case 'EVERY_MONTH': {
                    frequency.hideDay = true;
                    scheduleTakesIntervals(frequency, 1, 0, 1);

                    break;
                }
                case 'ONE_AM': {
                    scheduleTakesIntervals(frequency, 1, 0, 9);

                    break;
                }
                case 'ONE_HS': {
                    scheduleTakesIntervals(frequency, 1, 0, 21);

                    break;
                }
                case 'BID': {
                    scheduleTakesIntervals(frequency, 2, 12, 9);

                    break;
                }
                case 'TID': {
                    scheduleTakesIntervals(frequency, 3, 6, 9);

                    break;
                }
                case 'QID': {
                    scheduleTakesIntervals(frequency, 4, 3, 9);

                    break;
                }
                case 'SID': {
                    scheduleTakesIntervals(frequency, 6, 4, 2);

                    break;
                }
                case 'ONCE': {
                    scheduleTakesIntervals(frequency, 1, 0, 15);

                    break;
                }
                case 'STAT': {
                    frequency.schedules.push({
                        xorder: 0,
                        strength: null,
                        date: new Date(moment(new Date()).format('YYYY-MM-DDTHH:mm:00'))
                    });

                    break;
                }
            }
        }

        function scheduleTakesIntervals(frequency, count, hours, firstHour) {
            var first = moment().startOf('hour').toDate();
            first.setHours(firstHour);

            for (var i = 0; i < count; i++) {
                frequency.schedules.push({
                    xorder: i,
                    date: angular.copy(first),
                    strength: null
                });

                first.setHours(first.getHours() + hours);
            }
        }

        function add() {
            vm.pmedications.push({
                collapsed: false,
                startDate: new Date(),
                frequencies: [],
                onlyInChart: false
            })
        }

        function changeCategory(pmedication) {
            pmedication.frequencies = [];

            switch (pmedication.type) {
                case 'AS_NEEDED':
                case 'CONSISTENT_DOSE': {
                    addDay(pmedication);
                    pmedication.durationDays = null;

                    break;
                }
                case 'TAPER': {
                    addDay(pmedication);
                    pmedication.durationDays = pmedication.frequencies.length;

                    break;
                }
            }
        }

        function getSignatureOptions() {

            return {
                width: 620,
                height: 250,
                cssClass: 'signature-canvas',
                color: '#00008B',
                'background-color': '#fff'
            };
        }

        function checkAll() {
            _.forEach(vm.protocols, function (protocol) {
                vm.selected[protocol.id] = true;
            })
        }

        function unCheckAll() {
            _.forEach(vm.protocols, function (protocol) {
                vm.selected[protocol.id] = false;
            })
        }

        function getAdmissionMedications() {
            _.forEach(admissionMedications.ids, function (id) {
                AdmissionMedication.get({id: id}, function (admissionMedication) {
                    vm.pmedications.push(medicationFromAdmissionMedication(admissionMedication));
                })
            })
        }

        function getFilterByCorporationAndType() {
            var filter = {};

            filter.corporationId = CoreService.getCorporation().id;
            filter.relationalType = RATIONALE_TYPE.MEDICATION;

            return filter
        }

        function getEmployees(query) {
            if (vm.itHasCredentials) {
                return;
            }

            var filter = {
                page: 0,
                size: 200000,
                query: query,
                facilityId: CoreService.getCurrentFacility().id,
                businessRole: BUSINESS_ROLES.PHYSICIAN
            };

            Employee.findAllByFilter(filter, function (result) {
                vm.employees = result;
            });
        }
    }
})();
