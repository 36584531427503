(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('AdmissionMedicationDetailController', AdmissionMedicationDetailController);

    AdmissionMedicationDetailController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'Via', 'CoreService',
        'Route', 'admissionMedication', 'AdmissionMedication'];

    function AdmissionMedicationDetailController($timeout, $scope, $uibModalInstance, Via, CoreService,
                                               Route, admissionMedication, AdmissionMedication) {
        var vm = this;

        vm.admissionMedication = admissionMedication;
        vm.chart = admissionMedication.chart;

        vm.allergiesStr = "";
        vm.rationaleStr = "";
        vm.frequencies = [];

        init();

        function init() {
            AdmissionMedication.findAllFrequencies({id: vm.admissionMedication.id}, function (data) {
                vm.frequencies = _.sortBy(data, 'xorder');

                _.forEach(vm.frequencies, function (frequency) {
                    frequency.schedules = _.sortBy(frequency.schedules, 'xorder');

                    frequency.hideDay = false;

                    switch (frequency.frequency.id) {
                        case 'EVERY_H':
                        case 'EVERY_2H':
                        case 'EVERY_3H':
                        case 'EVERY_4H':
                        case 'EVERY_6H':
                        case 'EVERY_8H':
                        case 'EVERY_12H':
                        case 'EVERY_24H':
                        case 'EVERY_48H':
                        case 'EVERY_WEEK':
                        case 'EVERY_MONTH': {
                            frequency.hideDay = true;

                            break;
                        }
                    }
                });
            });

            if (vm.admissionMedication.relationals.length > 0) {
                vm.rationaleStr =
                    _.join(
                        _.map(vm.admissionMedication.relationals, function (relational) {
                            return relational.name;
                        }), ', ');
            }
        }

        vm.clear = clear;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
