(function () {
    'use strict';

    angular
        .module('bluebookApp')
        .controller('InsuranceBillingConfigController', InsuranceBillingConfigController);

    InsuranceBillingConfigController.$inject = ['InsuranceBillingConfig', 'CoreService', 'GenericEntityDatatableService',
        '$q', '$uibModal', 'ConfirmationService'];

    function InsuranceBillingConfigController(InsuranceBillingConfig, CoreService, GenericEntityDatatableService,
                                              $q, $uibModal, ConfirmationService) {
        var vm = this;

        var params = {
            facilityId: CoreService.getCurrentFacility().id
        }

        var entityStateName = 'insurance-billing-config'

        vm.descriptor = {
            title: 'Insurance Audit Config',
            entityClassHumanized: 'Insurance Audit Config',
            entityStateName: entityStateName,
            service: InsuranceBillingConfig,
            serviceMethod: 'filter',
            params: params,
            pagination: 'remote',
            newButtonTitle: 'New Insurance Audit Config',
            newAction: false,
            columns: [
                {
                    name: 'hours',
                    title: 'Hours'
                },
                {
                    title: 'Insurances',
                    render: function (data) {
                        var row = "";

                        _.forEach(data.insuranceCarriers, function (carrier) {
                            row += row.length === 0 ? carrier.name : ", " + carrier.name;
                        });

                        return row;
                    }
                },
                {
                    title: 'LOC',
                    render: function (data) {
                        var row = "";

                        _.forEach(data.typeLevelCares, function (level) {
                            row += row.length === 0 ? level.name : ", " + level.name;
                        });

                        return row;
                    }
                }
            ],
            rowActions: [
                angular.extend({}, GenericEntityDatatableService.getEditAction(), {
                    action: function (data) {
                        return edit(data.id);
                    }
                }),
                angular.extend({}, GenericEntityDatatableService.getDeleteAction(), {
                    action: function (data) {
                        return del(data.id);
                    }
                }),
            ],
            actions: [
                {
                    type: 'group',
                    buttons: [
                        angular.extend({}, GenericEntityDatatableService.getNewAction(), {
                            name: 'New Insurance Audit Config',
                            action: function () {
                                return add();
                            }
                        })
                    ]
                }
            ],
        }

        function add() {
            return $uibModal.open({
                templateUrl: 'app/entities/insurance-billing-config/update/insurance-billing-config-dialog.html',
                controller: 'InsuranceBillingConfigDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['CoreService', function (CoreService) {
                        return {
                            hours: null,
                            frequency: null,
                            id: null,
                            facility: CoreService.getCurrentFacility()
                        };
                    }]
                }
            }).result;
        }

        function edit(id) {
            return $uibModal.open({
                templateUrl: 'app/entities/insurance-billing-config/update/insurance-billing-config-dialog.html',
                controller: 'InsuranceBillingConfigDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    entity: ['InsuranceBillingConfig', function (InsuranceBillingConfig) {
                        return InsuranceBillingConfig.get({id: id}).$promise;
                    }]
                }
            }).result
        }

        function del(id) {
            return $q(function (resolve) {
                ConfirmationService.showDelete('Insurance Audit Config').result.then(function () {
                    InsuranceBillingConfig.delete({id: id}, function () {
                        return resolve();
                    });
                })
            });
        }
    }
})();
